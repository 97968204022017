import React, { useState, useEffect } from 'react';
import { fetchUserCards } from '../services/api';  // API call to fetch user-specific cards
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


const HomePage = () => {
  const [cards, setCards] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const loadCards = async () => {
      try {
        const response = await fetchUserCards(token);  // Fetch cards for logged-in user
        setCards(response.data);  // Assuming response.data contains the array of cards
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    };

    if (token) {
      loadCards();
    }
  }, [token]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Business Cards
      </Typography>
      {cards.length > 0 ? (
        <Grid container spacing={3}>
          {cards.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <Card sx={{ marginBottom: '20px' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {card.card_name}
                  </Typography>
                  <Typography variant="body2">
                    Email: {card.email}
                  </Typography>
                  <Typography variant="body2">
                    Phone: {card.phone}
                  </Typography>
                  <Typography variant="body2">
                    Website: {card.website}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">No cards available.</Typography>
      )}
    </Container>
  );
};

export default HomePage;
