import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.sasan.ai';

// Fetch all public cards
export const fetchPublicCards = async () => {
  return await axios.get(`${API_URL}/api/cards/`);
};

// Fetch cards for the authenticated user
export const fetchUserCards = async (token) => {
  return await axios.get(`${API_URL}/api/user/cards/`, {
    headers: { Authorization: `Bearer ${token}` },  // Include token for authenticated request
  });
};

// Add a new card
export const addCard = async (cardData, token) => {
  return await axios.post(`${API_URL}/api/cards/add/`, cardData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Update an existing card
export const updateCard = async (id, cardData, token) => {
  return await axios.put(`${API_URL}/api/cards/${id}/edit/`, cardData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Get a card by its ID
export const getCard = async (id, token) => {
  return await axios.get(`${API_URL}/api/cards/${id}/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
