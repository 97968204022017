import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { addCard } from '../services/api';  // Ensure the correct service path
import { QRCodeCanvas } from 'qrcode.react';  // Import QRCodeCanvas for QR code generation

const AddCardPage = ({ setSnackbar }) => {
  const [formData, setFormData] = useState({
    card_name: '',
    company: '',
    phone: '',
    email: '',
    website: '',
    address: '',
  });

  const [cardId, setCardId] = useState(null); // Store card ID for QR code generation
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('access_token');
    try {
      const response = await addCard(formData, token);  // API call to add a card
      setCardId(response.data.id); // Store the card ID after successful creation
      setSnackbar({
        open: true,
        message: 'Card added successfully!',
        severity: 'success',
      });
      navigate('/'); // Redirect to home page after successful card creation
    } catch (err) {
      setError('Failed to add card.');
      setSnackbar({
        open: true,
        message: 'Failed to add card.',
        severity: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <h2>Add New Card</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TextField
        label="Card Name"
        name="card_name"
        value={formData.card_name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Company"
        name="company"
        value={formData.company}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Website"
        name="website"
        value={formData.website}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit" fullWidth>
        Add Card
      </Button>

      {cardId && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <h3>QR Code for Card</h3>
          <QRCodeCanvas value={`https://sasan.ai/cards/${cardId}`} />
        </div>
      )}
    </form>
  );
};

export default AddCardPage;
