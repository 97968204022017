import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { loginUser } from '../services/auth';  // Correct path for the login service

const Login = ({ setSnackbar }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(formData);  // Call the login service
      localStorage.setItem('token', response.token);  // Save token to local storage
      setSnackbar({
        open: true,
        message: 'Login successful!',
        severity: 'success',
      });
      navigate('/cards');  // Redirect to the user's card list after successful login
    } catch (err) {
      setError('Login failed');
      setSnackbar({
        open: true,
        message: 'Login failed. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TextField
        label="Username"
        name="username"
        value={formData.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit" fullWidth>
        Login
      </Button>
    </form>
  );
};

export default Login;
