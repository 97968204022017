import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getCard, updateCard } from '../services/card';  // Import getCard and updateCard

const EditCardPage = ({ setSnackbar }) => {
  const [formData, setFormData] = useState({
    card_name: '',
    company: '',
    phone: '',
    email: '',
    website: '',
    address: '',
  });

  const [error, setError] = useState('');
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const loadCard = async () => {
      try {
        const response = await getCard(id);  // Fetch the card details by ID
        setFormData(response.data);  // Set the form data with the card details
      } catch (err) {
        console.error('Error fetching card:', err);
        setError('Error fetching card details.');
      }
    };
    loadCard();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCard(id, formData, token);  // Update the card details
      setSnackbar({
        open: true,
        message: 'Card updated successfully!',
        severity: 'success',
      });
      navigate('/user/cards');  // Redirect to user card list after updating
    } catch (err) {
      setError('Failed to update card.');
      setSnackbar({
        open: true,
        message: 'Failed to update card.',
        severity: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <h2>Edit Card</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TextField
        label="Card Name"
        name="card_name"
        value={formData.card_name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Company"
        name="company"
        value={formData.company}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Website"
        name="website"
        value={formData.website}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit" fullWidth>
        Update Card
      </Button>
    </form>
  );
};

export default EditCardPage;
