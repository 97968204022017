// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Login from './components/Login';
import Signup from './components/Signup';
import AddCardPage from './pages/AddCardPage';
import EditCardPage from './pages/EditCardPage';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AuthProvider } from './context/AuthContext';
import UserCards from './pages/UserCards';  // Correct path for UserCards
import NotFoundPage from './pages/NotFoundPage'; // Import NotFoundPage

const App = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <AuthProvider>
      <Router>
        <Header />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login setSnackbar={setSnackbar} />} />
          <Route path="/signup" element={<Signup setSnackbar={setSnackbar} />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path="/cards/add"
            element={
              <ProtectedRoute>
                <AddCardPage setSnackbar={setSnackbar} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cards/:id/edit"
            element={
              <ProtectedRoute>
                <EditCardPage setSnackbar={setSnackbar} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/cards"
            element={
              <ProtectedRoute>
                <UserCards />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
