import React, { useEffect, useState } from 'react';
import { fetchUserCards } from '../services/card';  // Ensure correct import
import { useNavigate } from 'react-router-dom';

const UserCards = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const loadCards = async () => {
      try {
        if (!token) {
          navigate('/login');  // Redirect to login if no token
          return;
        }

        const response = await fetchUserCards(token);  // Fetch cards for the logged-in user
        setCards(response.data);  // Assuming response.data contains the card data
        setLoading(false);
      } catch (err) {
        setError('Failed to load cards');
        setLoading(false);
        console.error('Error fetching user cards:', err);
      }
    };

    loadCards();
  }, [token, navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>My Business Cards</h2>
      {cards.length > 0 ? (
        cards.map((card) => (
          <div key={card.id}>
            <h3>{card.card_name}</h3>
            <p>Email: {card.email}</p>
            <p>Phone: {card.phone}</p>
          </div>
        ))
      ) : (
        <p>No cards available</p>
      )}
    </div>
  );
};

export default UserCards;
