// src/components/Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { registerUser } from '../services/auth';  // Import the correct function

const Signup = ({ setSnackbar }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await registerUser(formData);  // Call the correct register function
      localStorage.setItem('token', response.token);  // Save token to local storage
      setSnackbar({
        open: true,
        message: 'Signup successful!',
        severity: 'success',
      });
      navigate('/');  // Redirect to home page after successful signup
    } catch (err) {
      setError('Signup failed');
      setSnackbar({
        open: true,
        message: 'Signup failed. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <h2>Signup</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <TextField
        label="Username"
        name="username"
        value={formData.username}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit" fullWidth>
        Signup
      </Button>
    </form>
  );
};

export default Signup;
