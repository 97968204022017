import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.sasan.ai';

// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: `${API_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to attach the access token to all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle token expiration and refresh if needed
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      error.response.data.code === 'token_not_valid'
    ) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        try {
          const response = await axios.post(`${API_URL}/auth/token/refresh/`, {
            refresh: refreshToken,
          });

          const newAccessToken = response.data.access;
          localStorage.setItem('access_token', newAccessToken);

          // Retry the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.log('Refresh token expired or invalid');
          // Optionally handle logout or redirect to login page
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
        }
      }
    }

    return Promise.reject(error);
  }
);

// Fetch all public cards
export const fetchPublicCards = async () => {
  const response = await api.get('cards/');
  return response.data;
};

// Fetch user-specific cards
export const fetchUserCards = async (token) => {
  const response = await api.get('user/cards/', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Add a new card
export const addCard = async (cardData) => {
  const response = await api.post('cards/add/', cardData);
  return response.data;
};

// Update an existing card
export const updateCard = async (id, cardData) => {
  const response = await api.put(`cards/${id}/edit/`, cardData);
  return response.data;
};

// Get a card by its ID
export const getCard = async (id) => {
  const response = await api.get(`cards/${id}/`);
  return response.data;
};

// Export all functions as part of default
export default {
  fetchPublicCards,
  fetchUserCards,
  addCard,
  updateCard,
  getCard,
};
